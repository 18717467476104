<template>
    <div class="declare">
        <el-card class="box-card">
            <div class="top">
                <div style="font-size:16px;font-weight: bold;">申报记录</div>
                <div style="font-size:14px;color:#999">以下为最近30天的申报记录</div>
            </div>
            <el-divider></el-divider>
            <el-table
            :data="tableData"
            style="width: 100%"
            tooltip-effect
            :header-cell-style="{'text-align':'center','background':'#eee'}"
            :cell-style="{'text-align':'center'}"
        >
            <el-table-column label="政策名称"  prop="title" width="200">
                <template slot-scope="scope">
                     <el-tooltip class="item_tooltip" effect="dark" placement="top">
                        <div slot="content">
                            <div>{{scope.row.title}}</div>
                        </div>
                        <span>{{scope.row.title.slice(0,10) + '...'}}</span>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="申报主体"  prop="entityName"></el-table-column>
            <el-table-column label="联系人"  prop="contantName"></el-table-column>
            <el-table-column label="联系人电话"  prop="contantPhone" width="180"></el-table-column>
            <el-table-column label="提交日期"  prop="time" style="white-space:nowrap;">
                <template slot-scope="scope">
                     <el-tooltip class="item_tooltip" effect="dark" placement="top">
                        <div slot="content">
                            <div>{{scope.row.time}}</div>
                        </div>
                        <span>{{scope.row.time.slice(0,11)}}</span>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="状态"  prop="state">
                <template slot-scope="scope">
                    <span :style="scope.row.state == 1?'color:#156ED0':'color:gray'">{{scope.row.state == 1?'已提交':scope.row.state == 2?'跟进中':'已完结'}}</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNo"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
        </el-card>

    </div>
</template>

<script>
import { request } from '../../../network';
import { getMemberId } from '@/assets/public/utils/token';
export default {
  data () {
    return {
      tableData: [],
      total: 0,
      pageNo: 1,
      pageSize: 10
    };
  },
  mounted () {
    this.getDeclareLog(this.pageNo, this.pageSize);
  },
  methods: {
    async getDeclareLog (pageNo, pageSize) {
      const { data: res } = await request({
        method: 'POST',
        url: '/pcp/PersonalCenter/getDeclareLog',
        data: {
          memberId: getMemberId(),
          pageNo: pageNo,
          pageSize: pageSize
        }
      });
      if (res.code !== 200) this.$message.error('获取申报记录失败');
      this.tableData = res.data.list;
      this.total = res.data.total;
    },
    handleSizeChange (val) {
      this.getDeclareLog(this.pageNo, val);
    },
    handleCurrentChange (val) {
      this.getDeclareLog(val, this.pageSize);
    }
  }
};
</script>

<style lang="less" scoped>
    .pagination{
        margin-top: 20px;
        text-align: right;
    }
    .top{
        display: flex;
        justify-content: space-between;

    }
</style>
